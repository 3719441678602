import "@vendor-app/app/AdminRoute/AdminSmartrrAccount/components/AccountPlans.css";

import { Icon, LegacyStack, MediaCard, Page, Text } from "@shopify/polaris";
import { LockMajor } from "@shopify/polaris-icons";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

import BundlesGatedImage from "./../styles/BundlesGatedImage.png";
import IntegrationsGatedImage from "./../styles/IntegrationsGatedImage.png";
import LoyaltyGatedImage from "./../styles/LoyaltyGatedImage.png";
import ReportsGatedImage from "./../styles/ReportsGatedImage.png";
import RetentionGatedImage from "./../styles/RetentionGatedImage.png";
import TranslationsGatedImage from "./../styles/TranslationsGatedImage.png";
import { isFeatureAvailable } from "./featureAccess";

enum Path {
  RETENTION = "/admin/configure/churn-prevention",
  REPORTS = "/admin/analytics",
  BUNDLES = "/admin/configure/bundles",
  LOYALTY = "/admin/loyalty",
  INTEGRATIONS = "/admin/configure/integrations",
  TRANSLATIONS = "/admin/configure/translations",
  ANALYTICS = "admin/advanced-analytics",
}

const CardWrapper = styled.div`
  .Polaris-Button {
    background: #008060;
    color: #ffffff;

    &:hover {
      background: #006e52;
    }
  }
`;

interface IGatedCard {
  pageTitle?: string;
  buttonText: string;
  cardTitle: string;
  cardDescription: string;
  cardImage: string;
}

export function AuthorizedRoute(feature: FeatureEnum, component: React.ReactNode): React.ReactNode {
  const [cardContent, setCardContent] = useState<IGatedCard>();
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);

  useEffect(() => {
    setGatedCardContent();
  }, []);

  const setGatedCardContent = () => {
    switch (window.location.pathname) {
      case Path.RETENTION: {
        return setCardContent({
          pageTitle: "Retention",
          buttonText: "📈 Upgrade to Grow",
          cardTitle: "Retention unavailable on current plan",
          cardDescription:
            "Upgrade to Grow to gain insight on attempted customer cancellations and improve retention over time.",
          cardImage: RetentionGatedImage,
        });
      }
      case Path.BUNDLES: {
        return setCardContent({
          pageTitle: "Bundles",
          buttonText: "💎 Upgrade to Excel",
          cardTitle: "Bundles unavailable on current plan",
          cardDescription: "Upgrade to Excel to offer custom 'build-a-box' type subscriptions to your customers.",
          cardImage: BundlesGatedImage,
        });
      }
      case Path.INTEGRATIONS: {
        return setCardContent({
          pageTitle: "Integrations",
          buttonText: "📈 Upgrade to Grow",
          cardTitle: "Integrations unavailable on current plan",
          cardDescription:
            "Upgrade to Grow to access our integrations with Klaviyo, LoyaltyLion, Gorgias, Postscript, & more. You'll also be able to create Smartrr API keys and set up webhooks.",
          cardImage: IntegrationsGatedImage,
        });
      }
      case Path.LOYALTY: {
        return setCardContent({
          pageTitle: "Loyalty",
          buttonText: "💎 Upgrade to Excel",
          cardTitle: "Loyalty unavailable on current plan",
          cardDescription:
            "Upgrade to Excel to incentivize your customers to earn and redeem rewards through different shop touchpoints—including referrals.",
          cardImage: LoyaltyGatedImage,
        });
      }
      case Path.TRANSLATIONS: {
        return setCardContent({
          pageTitle: "Translations",
          buttonText: "📈 Upgrade to Grow",
          cardTitle: "Translations unavailable on current plan",
          cardDescription:
            "Upgrade to Grow to translate your Customer Account Portal from English to French or Spanish.",
          cardImage: TranslationsGatedImage,
        });
      }
      case Path.REPORTS: {
        return setCardContent({
          pageTitle: "Reports",
          buttonText: "📈 Upgrade to Grow",
          cardTitle: "Reports unavailable on current plan",
          cardDescription:
            "Upgrade to Grow to get access to basic analytics reporting. Or, upgrade to Excel to get access to our robust Advanced Analytics reporting powered by Looker.",
          cardImage: ReportsGatedImage,
        });
      }
      default: {
        return <div></div>;
      }
    }
  };

  if (isFeatureAvailable(feature, user, activePlan)) {
    return component;
  }

  return (
    <Page narrowWidth title={cardContent?.pageTitle || undefined}>
      <CardWrapper>
        <MediaCard
          title={
            <LegacyStack spacing="tight" wrap={false}>
              <Icon source={LockMajor} color="base" />
              <Text variant="headingMd" as="h2">
                {cardContent?.cardTitle}
              </Text>
            </LegacyStack>
          }
          primaryAction={{
            content: `${cardContent?.buttonText}`,
            onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/smartrr-account`),
          }}
          description={cardContent?.cardDescription || ""}
        >
          <img
            alt=""
            width="100%"
            height="100%"
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            src={cardContent?.cardImage}
          />
        </MediaCard>
      </CardWrapper>
    </Page>
  );
}
